import React from 'react';
import Helmet from 'react-helmet';

import Layout from '../components/layout';
import { withPrefix } from 'gatsby';
import ContactSVG from '../components/backgroundSVGS/ContactSVG';

const SuccessPage = () => (
    <Layout>
        <Helmet
            title="Message sent | Element Studio - Cheltenham Web Design &amp; Development"
            meta={[
                {
                    name: 'description',
                    content:
                        'Element Studio is a Web Design and Development agency based in Cheltenham, specialising in bespoke web applications built in Laravel PHP and React JS.'
                }
            ]}
        />
        <header className="hero">
            <div className="hero__inner">
                <div className="hero-content">
                    <h1 className="type-backdrop">
                        <ContactSVG />
                    </h1>
                    <h2 className="type-title type-uppercase _has-subhead">Thanks for getting in touch</h2>
                    <span className="type-metadata">We'll be sure to get back to you shortly</span>
                </div>
            </div>
            <svg className="signpost-center" viewBox="0 0 8 74" width="8" height="74">
                <use xlinkHref="#icon-arrow-down" />
            </svg>
        </header>

        <section className="wrapper-chunky">
            <div className="text-align--center">
                <span className="type-label">
                    Your note has been left in a safe place. Thanks again
                    <span className="type-highlight">!</span>
                </span>
            </div>
        </section>

        <section className="wrapper-chunky bg-white">
            <div className="container-narrow">
                <div className="grid-x grid-margin-x small-only-rhythm">
                    <div className="cell small-12 medium-4">
                        <h4 className="type-small-heading">
                            HQ<span className="type-highlight">&nbsp;//</span>
                        </h4>
                        <a
                            href="https://www.google.com/maps/place/Element+Studio/@51.8969685,-2.0769041,15z/data=!4m2!3m1!1s0x0:0xf1de554285b621b3?sa=X&ved=2ahUKEwifrK3e563uAhUkWhUIHb5KBlgQ_BIwC3oECBwQBQ"
                            target="_blank"
                            rel="nofollow noopener noreferrer"
                            title="View address on map"
                        >
                            <address className="type-address">
                                <svg width="20" height="20" viewBox="0 0 48 48" className="address-icon">
                                    <use xlinkHref="#icon-pin" />
                                </svg>
                                16 Imperial Square,
                                <br />
                                Cheltenham,
                                <br />
                                Gloucestershire.
                                <br />
                                GL50 1QZ
                            </address>
                        </a>

                    </div>
                    <div className="contact_region cell small-12 medium-8 large-7 large-offset-1 text-align--right relative">
                        <img src={withPrefix('images/contact-map.svg')} alt="A map of the south west of England" />
                        <div className="contact_region_details">
                            <a
                                href="https://www.google.co.uk/maps/place/Element+Studio/@51.9004902,-2.0815793,17z/data=!4m5!3m4!1s0x48711b93cb074297:0xf1de554285b621b3!8m2!3d51.9004869!4d-2.0793906"
                                className="type-label type-textlink"
                                target="_blank"
                                rel="nofollow noopener noreferrer"
                                title="View full map"
                            >
                                View full map
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </Layout>
);

export default SuccessPage;
